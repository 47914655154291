'use client'

import { Button } from '@nextui-org/button'

import { Link } from '@/libs/intl'

export default function NotFound() {
  // __RENDER
  return (
    <div className='container grid min-h-screen'>
      <div className='flex flex-col gap-1 place-self-center text-center'>
        <h1 className='mb-4 text-9xl font-extrabold'>404</h1>
        <h3 className='text-4xl font-bold capitalize'>oops, this page not found!</h3>
        <p className='text-2xl font-extralight'>The link might be corrupted</p>
        <p className='text-white/75'>or The page may have been removed.</p>

        <div className='flex items-center justify-center'>
          <Button className='font-semibold' as={Link} href='/' color='primary' radius='sm'>
            Go back home
          </Button>
        </div>
      </div>
    </div>
  )
}
